import React from "react"
import MainMenu from "../components/mainmenu"
import Footer from "../components/footer"
import GlobalStyle from "../components/global_style"

import Logo from "../images/LAU_logo.svg"

const Layout = props => (
    <div>
        <GlobalStyle />
        <MainMenu logo={Logo}
            entries={
                [
                    {name:"Unternehmen", link:"/unternehmen"},
                    {name:"Leistungen", link:"/leistungen"},
                    {name:"Fuhrpark", link:"/fuhrpark"},
                    {name:"Kontakt", link:"/kontakt"},
                ]}
            active={props.active}
            allwaysTop={props.allwaysTop}
        />
        <div>
            {props.children}
        </div>
        <Footer />
    </div>
)

export default Layout 