import React from "react"
import { Link } from "gatsby"

import { IconContext } from "react-icons";
import { FaPhone, FaFax, FaMailBulk, FaRegWindowMaximize, FaAtlas, FaFacebookSquare, FaInstagram } from "react-icons/fa"

import Logo from "../images/LAU_logo_footer.svg"

import footerStyles from "./footer.module.scss"

const Footer = () => (
    <footer style={{background: 'white', overflow:'auto'}}>
        <div className={footerStyles.footer_container}>
            <div className="content_wrapper">

                <div className={footerStyles.flex_container} style={{textAlign: "center"}}>
                    <div className={footerStyles.brand}>
                        <IconContext.Provider value={{size: "2.5em"}}>
                        <Link className={footerStyles.link} to="/">
                            <img src={Logo} alt="Schwertransport Lau Rostock" />
                        </Link>
                        <a className={footerStyles.link} href="https://www.facebook.com/Schwertransport/"><FaFacebookSquare /></a>
                        &nbsp;&nbsp;
                        <a className={footerStyles.link} href="https://www.instagram.com/lau_rostock/"><FaInstagram /></a>
                        &nbsp;&nbsp;
                        </IconContext.Provider>
                    </div>
                    <IconContext.Provider value={{size: "1.25em", style: { verticalAlign: 'middle'}}}>

                    <div className={footerStyles.footer_menu}>
                        <h3 style={{textAlign: "center"}}>Kontakt</h3>
                        <FaRegWindowMaximize />&nbsp;&nbsp;&nbsp;<a href="http://www.lau-rostock.de">www.lau-rostock.de</a><br />
                        <FaMailBulk />&nbsp;&nbsp;&nbsp;<a href="mailto:info@lau-rostock.de" >info@lau-rostock.de</a><br />
                        <br />
                        <FaPhone />&nbsp;&nbsp;&nbsp;<a href="tel:+49381444870">+49 381 4444 87 0</a><br />
                        <FaFax />&nbsp;&nbsp;&nbsp;+49 381 4444 87 22<br />
                    </div>
                    <div className={footerStyles.footer_menu}>
                        <h3 style={{textAlign: "center"}}>Adresse</h3>
                        <FaAtlas /><br />
                        Schwertransport Lau GmbH &amp; Co. KG<br/>
                        Sandkrug 6<br />
                        D - 18059 Papendorf b. Rostock<br />
                    </div>
                    </IconContext.Provider>
                    <div className={footerStyles.footer_menu}>
                        <h3 style={{textAlign: "center"}}>Rechtliches</h3>
                        <Link className={footerStyles.link} to="/impressum">Impressum</Link><br />
                        <Link className={footerStyles.link} to="/datenschutz">Datenschutz</Link><br />
                        <Link className={footerStyles.link} to="/social">Social Media</Link><br />
                        <a href="../../BSK-AGB, Stand November 2020, deutsch.pdf" target="_blank" rel="noopener" >AGB Kran und Transport</a><br />
                        <a href="../../GB_BSK-AGB, Stand November 2020.pdf" target="_blank" rel="noopener" >GTC crane and haulage (english)</a><br />
                        <a href="../../AGB-Sicherung-GST_2001.pdf" target="_blank" rel="noopener" >AGB Begleitung und Sicherung</a><br />
                    </div>
                </div>
                <hr />
                <nav className={footerStyles.bottom_navbar}>
                    <div className={footerStyles.bottom_copy}>© {new Date().getFullYear()}, Schwertransport Lau GmbH &amp; Co KG</div>
                </nav>
            </div>
        </div>
    </footer>
)

export default Footer